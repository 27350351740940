<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="selected"
    @input="$emit('input', $event)"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: false }"
    :items="EDUCATION_LANGUAGES"
    :multiple="multiple"
    item-text="text"
    item-value="value"
  >
    <template v-if="multiple" v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.text }}</span>
      <span v-if="index === 1" class="ml-2 grey--text caption">(+{{ selected.length - 1 }})</span>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.text }}
    </template>

    <template slot="item" slot-scope="data">
      {{ data.item.text }}
    </template>
  </v-autocomplete>
</template>

<script>
  import EDUCATION_LANGUAGES from "./education_languages";

  export default {
    inheritAttrs: false,
    name: "EducationLanguagePicker",
    props: {
      value: [String, Array],
      multiple: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },
    data: vm => ({
      EDUCATION_LANGUAGES,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
