import { i18n } from "@/plugins/i18n";
import { EDUCATION_LANGUAGE } from "@/helpers/enums";
export default [
  {
    text: i18n.t("education_languages.turkish"),
    value: EDUCATION_LANGUAGE.TURKISH,
    disabled: false
  },
  {
    text: i18n.t("education_languages.english"),
    value: EDUCATION_LANGUAGE.ENGLISH,
    disabled: false
  },
  {
    text: i18n.t("education_languages.german"),
    value: EDUCATION_LANGUAGE.GERMAN,
    disabled: false
  },
  {
    text: i18n.t("education_languages.french"),
    value: EDUCATION_LANGUAGE.FRENCH,
    disabled: false
  },
  {
    text: i18n.t("education_languages.dutch"),
    value: EDUCATION_LANGUAGE.DUTCH,
    disabled: false
  },
  {
    text: i18n.t("education_languages.spanish"),
    value: EDUCATION_LANGUAGE.SPANISH,
    disabled: false
  },
  {
    text: i18n.t("education_languages.italian"),
    value: EDUCATION_LANGUAGE.ITALIAN,
    disabled: false
  },
  {
    text: i18n.t("education_languages.arabic"),
    value: EDUCATION_LANGUAGE.ARABIC,
    disabled: false
  },
  {
    text: i18n.t("education_languages.russian"),
    value: EDUCATION_LANGUAGE.RUSSIAN,
    disabled: false
  },
  {
    text: i18n.t("education_languages.persian"),
    value: EDUCATION_LANGUAGE.PERSIAN,
    disabled: false
  },
  {
    text: i18n.t("education_languages.chinese"),
    value: EDUCATION_LANGUAGE.CHINESE,
    disabled: false
  },
  {
    text: i18n.t("education_languages.japanese"),
    value: EDUCATION_LANGUAGE.JAPANESE,
    disabled: false
  },
  {
    text: i18n.t("education_languages.hindi"),
    value: EDUCATION_LANGUAGE.HINDI,
    disabled: false
  },
  {
    text: i18n.t("education_languages.latin"),
    value: EDUCATION_LANGUAGE.LATIN,
    disabled: false
  },
  {
    text: i18n.t("education_languages.portuguese"),
    value: EDUCATION_LANGUAGE.PORTUGUESE,
    disabled: false
  },
  {
    text: i18n.t("education_languages.korean"),
    value: EDUCATION_LANGUAGE.KOREAN,
    disabled: false
  }
];
